window.SocialButtons = {
	init: () => {
		const socialButtons = document.querySelectorAll('.social-media-buttons > div');

		let i = 0;
		setInterval(() => {
			const lastIndex = socialButtons.length - 1;
			const isLastIndex = i === lastIndex;
			const isFirstIndex = i === 0;

			const button = socialButtons[i];
			const previousButton = socialButtons[isFirstIndex ? lastIndex : i - 1];

			button.classList.remove('hidden');

			if (previousButton) {
				previousButton.classList.add('hidden');
			}

			i = isLastIndex ? 0 : i + 1;
		}, 10000);
	}
}